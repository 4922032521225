import {Advertisement, Status} from "../../content/adDetailsPage/commands/types/Advertisement";
import {ReactNode} from "react";
import {ReactComponent as ActiveIcon} from "./resources/ActiveIcon.svg";
import {ReactComponent as ArchivedIcon} from "./resources/ArchivedIcon.svg";
import {ReactComponent as DeclinedIcon} from "./resources/DeclinedIcon.svg";
import {ReactComponent as AwaitingIcon} from "./resources/AwaitingIcon.svg";
import {omit, omitBy} from "lodash-es";
import {CatalogDecorator} from "../../common/commands/catalog/CatalogDecorator";
import {getThumbnailPath} from "../../common/utils/ImagesUtils";
import {formatDate} from "../../common/utils/DateUtils";
import {addSpacesFromEnd} from "../../common/utils/StringUtils";
import "./styles/advertisement.scss";

export default class AdvertisementDecorator {
    src: Advertisement;
    private _isPromoted: boolean = false;

    constructor(src: Advertisement) {
        this.src = src;
    }

    get id(): string {
        return this.src.id ?? "";
    }

    get title(): string {
        return this.src.title ?? "";
    }

    get images(): string[] {
        return this.src.images ?? [];
    }

    get thumbnails(): string[] {
        return this.images.map((item: string) => {
            return getThumbnailPath(item);
        });
    }

    get price(): string {
        if (this.src.price) {
            return `${addSpacesFromEnd(this.src.price)} грн`;
        }
        return "0 грн";
    }

    get status(): Status | undefined {
        return this.src.status;
    }

    get displayStatus(): ReactNode {
        let status: string;
        let icon: ReactNode;

        switch (this.status) {
            case Status.DRAFT:
                status = "Очікує модерації";
                icon = (<AwaitingIcon/>);
                break;
            case Status.DECLINED:
                status = "Відхилено";
                icon = (<DeclinedIcon/>);
                break;
            case Status.ARCHIVED:
                status = "Архівне";
                icon = (<ArchivedIcon/>);
                break;
            case Status.INACTIVE:
                status = "Неактивне";
                icon = (<ArchivedIcon/>);
                break;
            case Status.ACTIVE:
                icon = (<ActiveIcon/>);
                status = "Активне";
                break;
            default:
                status = "";
                icon = null;
                break;
        }

        return (<span className="display-status">{icon}{status}</span>);
    }

    get availableTo() {
        return formatDate(this.src.expiresOn);
    }

    get moderatorComment(): string {
        return JSON.parse(this.src.moderatorComment ?? "");
    }

    get characteristics(): Record<string, string> {
        const knownProps: string[] = ["authorId", "category", "createdOn", "description", "email", "expiresOn", "id", "images", "location",
            "phoneNumber", "price", "priority", "schemaId", "shop", "status", "title", "moderatorId", "moderatorComment"
        ];

        return omitBy(omit(this.src, knownProps), (value: any) => value === "Інше");
    }

    get createdOn() {
        return formatDate(this.src.createdOn);
    }

    get email(): string {
        return this.src.email ?? "";
    }

    get phoneNumber(): string {
        return this.src.phoneNumber ?? "";
    }

    get location(): string {
        return this.src.location ?? "";
    }

    get category(): string | undefined {
        return this.src["category"];
    }

    get isPromoted(): boolean {
        return this._isPromoted;
    }

    set isPromoted(value: boolean) {
        this._isPromoted = value;
    }

    editDetails(catalog: CatalogDecorator) {
        const knownProps: string[] = ["authorId", "category", "expiresOn", "id", "priority", "schemaId", "shop", "status"];
        const result: Record<string, string | string[] | undefined> = omitBy(omit(this.src, knownProps), (value: any) => value === "Інше");
        result.category = catalog.pathToLeaf(this.src.category)?.split(".");
        return result;
    }
}