import {AdvertisementPublicInfo} from "./AdvertisementPublicInfo";

export interface Advertisement extends AdvertisementPublicInfo {
    moderatorComment?: string;
    status?: Status;
}

export enum Status {
    DRAFT = "DRAFT",
    ACTIVE = "ACTIVE",
    DECLINED = "DECLINED",
    ARCHIVED = "ARCHIVED",
    INACTIVE = "INACTIVE"
}