import {FC} from "react";
import {useCurrentUser} from "../../commands/user/useCurrentUser";
import {useLocation} from "react-router-dom";
import {SavedAds} from "../header/savedAds/SavedAds";
import {Messages} from "../header/messages/Messages";
import {UserProfileMenu} from "../header/userProfileMenu/UserProfileMenu";
import {CreateNewAdButton} from "../header/createNewAd/CreateNewAdButton";
import {
    isCreateNewAdButtonVisible,
    isMessageButtonVisible,
    isProfileButtonVisible,
    isSearchVisible
} from "../../utils/LocationUtils";
import {MobileDetailedSearch} from "./mobileDetailedSearch/MobileDetailedSearch";
import {Affix} from "antd";
import {Pages} from "../../../routing/Pages";
import "./styles/mobileUSerLinks.scss";

export const MobileUserLinks: FC = () => {
    const [user] = useCurrentUser();
    const location = useLocation();

    if ([Pages.LOGIN, Pages.REGISTER_BUSINESS_ACCOUNT].includes(location.pathname as Pages)) {
        return null;
    }

    return (
        <Affix offsetBottom={0}>
            <div className="mobile-user-link">
                {isSearchVisible(location.pathname, user) && (<MobileDetailedSearch/>)}
                {isMessageButtonVisible(location.pathname, user) && (<SavedAds/>)}
                {isCreateNewAdButtonVisible(location.pathname, user) && (<CreateNewAdButton/>)}
                {isMessageButtonVisible(location.pathname, user) && (<Messages/>)}
                {isProfileButtonVisible(location.pathname) && (<UserProfileMenu/>)}
            </div>
        </Affix>

    );
}