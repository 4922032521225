import {FC, ReactNode, useEffect, useState} from "react";
import {Select} from "antd";
import {isEmpty} from "lodash-es";
import {addLevelsToOptions, findOptions, parseOption, SimpleTree, TreeOption, TreeValue} from "./Utils";
import "./styles/categoriesSelect.scss";

interface Props {
    options: TreeOption[];
    onChange?: (value: string[]) => void;
    onSelectionChange?: (value: string[]) => void;
    mode?: "single" | "multiple";
    value?: string[];
}

export const CategoriesSelect: FC<Props> = (props: Props) => {
    const {options, value = [], onChange, mode = "multiple", onSelectionChange} = props;
    const [treeValue, setValue] = useState<SimpleTree>(new SimpleTree(SimpleTree.arrayToTree(value)));
    const updateTree = (option: TreeOption, tree: TreeValue) => {
        if (!option) {
            treeValue.deleteValue(tree.level ?? 0);
        } else {
            const {level, value, id} = parseOption(option as TreeOption);
            const leave: TreeValue = {value, id};
            if (treeValue.hasValue(level)) {
                treeValue.updateValue(level, leave);
            } else {
                treeValue.addValue(leave);
            }
        }
    };

    const handleChange = (_: string, option: TreeOption, tree: TreeValue) => {
        updateTree(option as TreeOption, tree);

        const newValue: SimpleTree = new SimpleTree(treeValue.tree);
        setValue(newValue);
        if (mode === "single") {
            if (!option) {
                onChange?.([]);
                onSelectionChange?.([]);
            } else {
                const value: string[] = SimpleTree.treeToArray(newValue.tree);
                if (!option.children) {
                    onChange?.(value);
                }
                onSelectionChange?.(value);
            }
        } else {
            const value: string[] = SimpleTree.treeToArray(newValue.tree);
            onChange?.(value);
            onSelectionChange?.(value);
        }
    };

    const renderTree = (tree: TreeValue, options: TreeOption[], onChange: any): ReactNode => {
        const hasChildOptions: boolean = !isEmpty(findOptions(options, tree));
        const handleChange = (value: string, option: TreeOption | TreeOption[]) => {
            onChange(value, option, tree);
        };
        const value: string | null = tree.value ? `${tree.value}_${tree.level}` : null;
        return (
            <>
                <Select<string, TreeOption> value={value} options={options} onChange={handleChange}
                                            placeholder="Оберіть категорію" allowClear>
                    {options.map((item: TreeOption) => {
                        return (<Select.Option value={item.value}>{item.label}</Select.Option>);
                    })}
                </Select>
                {hasChildOptions && renderTree(tree.child ?? {}, findOptions(options, tree), onChange)}
            </>
        );
    };
    useEffect(() => {
        if(!isEmpty(value)) {
            setValue(new SimpleTree(SimpleTree.arrayToTree(value)));
        }
    }, [value]);

    return (
        <div className="categories-select">
            {renderTree(treeValue.tree, addLevelsToOptions(options, 0), handleChange)}
        </div>
    );
};
